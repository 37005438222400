import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 10000,
  params: {},
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// instance.interceptors.request.use(
//   async function (config) {
//     config.headers.api_key = 'pub_dbsXP6nVWLDzMpJ_n6-Z9GCXrF6Fd12q7k_pBe';
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   },
// );

export default instance;
