import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { addActionToast, removeActionToast, updateProgress } from './toastActionSlice';

export const downloadSlice = createSlice({
  name: 'download',
  initialState: {},
  reducers: {},
});

export const downloadFile =
  ({ url, filename, onSuccess }) =>
  async (dispatch, getState) => {
    try {
      const id = nanoid();
      let makeToast = true;
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: event => {
          const progress = event.progress * 100;
          if (makeToast) {
            const toast = {
              error: false,
              progress: progress,
              text: filename,
              type: 'DOWNLOAD',
              fileSize: event.total / 1024,
              id,
            };
            dispatch(addActionToast(toast));
            if (progress === 100) {
              onSuccess && onSuccess();
              setTimeout(() => {
                dispatch(removeActionToast(toast));
              }, 5000);
            }
            makeToast = false;
          } else {
            const toasts = getState().toastAction.toasts;
            const toast = toasts.find(singleToast => singleToast.id === id);
            dispatch(updateProgress({ id: toast.id, progress }));
            if (progress === 100) {
              onSuccess && onSuccess();
              setTimeout(() => {
                dispatch(removeActionToast(toast));
              }, 5000);
            }
          }
        },
      });

      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {} = downloadSlice.actions;
export default downloadSlice.reducer;
