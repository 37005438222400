import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CrossIcons.svg';
import Button from '../button/button';

const Signature = ({
  name,
  onChange = () => {},
  showRequiredError,
  signatureValue = null,
  sub_label = null,
  pageWidth,
  heightcanva,
}) => {
  const signRef = useRef();
  const containerRef = useRef();

  const [canvasWidth, setCanvasWidth] = useState(300);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);

  useEffect(() => {
    if (containerRef?.current) {
      setCanvasWidth(containerRef?.current.getBoundingClientRect().width - 2);
    }
  }, [containerRef?.current, pageWidth]);

  useEffect(() => {
    if (signRef?.current && signatureValue && containerRef?.current) {
      const canvasWidth = containerRef?.current.getBoundingClientRect().width;
      signRef.current?.clear();
      signRef?.current.fromDataURL(signatureValue, { width: canvasWidth, height: heightcanva });
    }
  }, [signRef?.current]);

  return (
    <div className="flex-column justify-center" ref={containerRef}>
      <div className="mb-1 flex items-center justify-between">
        <span className="inter-500-text font-14 line-height-20 natural-900-text mb-1">{name}</span>
        <Button
          onClick={() => {
            signRef.current?.clear();
            setIsCanvasEmpty(signRef.current?.isEmpty());
            onChange(null);
          }}
          label="Clear"
          size="small"
          className="border bg-white py-0_5"
          lableSize="line-height-20 font-12 natural-700-text"
          afterIcon={<CloseIcon className="mr-0" style={{ width: '14px', height: '14px' }} />}
          width="auto"
        />
      </div>
      <SignatureWrapper className="flex-column items-center w-full">
        <SignaturePad
          ref={signRef}
          onEnd={() => {
            onChange(signRef.current?.toDataURL());
          }}
          canvasProps={{
            className: `signCanvas border radius-1_5 ${showRequiredError && isCanvasEmpty && 'required-error'}`,
            width: canvasWidth,
            height: heightcanva,
          }}
          penColor="#16192C"
        />
      </SignatureWrapper>
      {sub_label && (
        <div className="mt-1">
          <span className="regular-text gray-500-text">{sub_label}</span>
        </div>
      )}
    </div>
  );
};

const SignatureWrapper = styled('div')`
  .signCanvas {
    @media (max-width: 425px) {
      height: 195px !important;
    }
    @media (max-width: 320px) {
      height: 95px !important;
    }
  }

  .required-error {
    border-color: ${({ theme }) => theme.ZervioNegative};
  }
`;

export default Signature;
