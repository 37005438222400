import classNames from 'classnames';
import React from 'react';
import { capitalize } from '../../../helpers/utils';

const TableHeader = ({ headers, headerClassName = '', headerLabelClassName = '' }) => {
  return (
    <div
      className={classNames(' header-container letter-spacing-1', headerClassName)}
      style={{ position: 'sticky', top: 0, zIndex: 9 }}>
      {headers.map(header => (
        <label key={header.key} className={classNames('flex items-center ', headerLabelClassName)}>
          {capitalize(header.name)}
        </label>
      ))}
    </div>
  );
};

export default TableHeader;
