import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const IconContainer = ({
  Icon,
  iconHeight = 16,
  iconWidth = 16,
  backgroundColor = 'natural_150',
  iconColor = 'natural_500',
  iconClassName = '',
  iconContainerClassname = '',
  onClick = () => {},
}) => {
  return (
    <IconContainerWrapper
      backgroundColor={backgroundColor}
      iconColor={iconColor}
      onClick={onClick}
      className={classNames('flex items-center justify-center pxy-1 radius-1', iconContainerClassname)}>
      <Icon height={iconHeight} width={iconWidth} className={classNames('icon', iconClassName)} />
    </IconContainerWrapper>
  );
};

const IconContainerWrapper = styled.div`
  background: ${({ theme, backgroundColor }) => theme[backgroundColor]};

  .icon {
    color: ${({ theme, iconColor }) => theme[iconColor]};
  }
`;

export default IconContainer;
