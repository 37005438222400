import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const variants = {
  size_44: {
    height: '44px',
    padding: '12px 16px',
  },
  size_32: {
    height: '32px',
    padding: '8px 12px',
  },
};

const InputElement = ({
  name,
  is_required = false,
  value,
  defaultValue,
  onChange = () => {},
  placeholder,
  className = '',
  error,
  disabled = false,
  error_text = '',
  extra_text = '',
  autofill = 'off',
  sub_name = '',
  type = 'text',
  variant = 'size_44',
}) => {
  const isTextArea = type === 'textarea';
  return (
    <InputElementWrapper className={className} variant={variants[variant]}>
      {name && (
        <div className="mb-1 one-line">
          <span className="inter-500-text natural-900-text">{name}</span>
          {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>}
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      <div className="relative">
        {isTextArea ? (
          <textarea
            className={classNames(
              'inter-400-text natural-900-text w-full input-element textarea-element',
              error && 'input-error',
            )}
            defaultValue={defaultValue}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={({ target: { value } }) => onChange(value)}
          />
        ) : (
          <input
            autoComplete={autofill}
            className={classNames('inter-400-text natural-900-text w-full input-element', error && 'input-error')}
            defaultValue={defaultValue}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            onChange={({ target: { value } }) => onChange(value)}
          />
        )}
        {extra_text && (
          <span className="extra-text inter-400-text font-14 natural-900-text absolute">{extra_text}</span>
        )}
      </div>
      {error && error_text && (
        <div>
          <span className="inter-400-text font-12 error-text">{error_text}</span>
        </div>
      )}
    </InputElementWrapper>
  );
};

const InputElementWrapper = styled('div')`
  .input-element {
    outline: none;
    height: ${({ variant }) => variant.height};
    border: 1px solid ${({ theme }) => theme.lightGrey};
    border-radius: 6px;
    padding: ${({ variant }) => variant.padding};

    &:focus {
      border: 1px solid ${({ theme }) => theme.focus_border};
      box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
    }

    &::placeholder {
      color: ${({ theme }) => theme.natural_400};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .textarea-element {
    height: 104px;
    resize: none;
  }

  .extra-text {
    right: 20px;
    padding: 12px 0;
  }

  .input-error {
    border-color: ${({ theme }) => theme.error_500};

    &:hover {
      border-color: ${({ theme }) => theme.error_500};
    }

    &:focus {
      border-color: ${({ theme }) => theme.error_500};
      box-shadow: none;
    }
  }
`;

export default InputElement;
