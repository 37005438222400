export const TRANSLATIONS_EN_GB = {
  TOTAL: 'Total',
  QUOTE_EXPIRES: 'Quote expires',
  COMPANY_NAME: 'Comapny Name',
  JOB_NUMBER: 'Job Number',
  JOB_ADDRESS: 'Job Address',
  CREATED_DATE: 'Created date',
  ISSUED_DATE: 'Issued date',
  EXPIRY_DATE: 'Expiry date',
  DESCRIPTION_QUOTE: 'Description',
  SUB_TOTAL: 'Sub-total',
  DISCOUNT: 'Discount',
  TAX: 'Tax',
  TOTAL: 'Total',
  DEPOSIT: 'Deposit',
  MILESTONE: 'Milestone',
  DUE_AFTER: 'Due after',
  ACCEPT: 'Accept',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  DECLINE: 'Decline',
  REJECT: 'Reject',
  CANCEL: 'Cancel',
  SIGNATURE: 'Signature',
  SIGN_HERE: 'Sign here',
  NOTE: 'Note',
  QUOTE: 'Quote',
  TERMS: 'Terms',
  PAYMENT_TERMS: 'Payment terms',
  NO_PAYMENT_TERMS: 'No payment terms',
  NO_PRODUCT: 'No Product',
  REJECTED_QUOTES: 'Quote has been Rejected',
  FAILED_CANCELLED_QUOTES: 'Failed to cancel Quotes',
  ACCEPTED_QUOTES: 'Quotes has been Accepted',
  FAILED_ACCEPTED_QUOTES: 'Failed to cancel Accepted',
  ERROR_QUOTES_DETAILS: 'Error while fetching quote details',
  REJECT_QUOTES_TITLE: 'Are you sure you want to reject this quote?',
  ACCEPT_QUOTES_TITLE: 'Are you sure you want to accept this quote?',
  QUOTES_INFO_TITLE: 'Quote info',
  QUOTES_INFO_DESCRIPTION:
    'This quote is currently being revised, if you think this is a mistake please phone your quote provider',
  QUOTES_REJECTION: 'Some notes about quote rejection',
  DESCRIPTION: 'Description',
  QTY: 'Qty',
  UNIT_PRICE: 'Unit Price',
  DISCOUNT: 'Discount',
  TOTAL: 'Tax',
  TOTAL: 'Amount',
  QUOTE_NUMBER: 'Quote number',
  ATTACHMENTS: 'Attachments',
  TERMS: 'Terms',
};
