import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { getFormattedNumber, getFormattedNumberStyle } from '../../helpers/utils';

const QuoteProductListItem = ({ listItem }) => {
  return (
    <QuoteProductListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={''}
      className={classNames('data-container bg-white py-2 cursor border-top')}>
      <div className="flex items-baseline overflow-hidden border-right pxy-2">
        <label className="inter-400-text natural-900-text line-height-20 font-14 respo-font-text">
          {listItem?.description || '-'}
        </label>
      </div>
      <div className="flex items-baseline overflow-hidden border-right pxy-2">
        <label className="inter-400-text natural-900-text two-lines line-height-20 font-14 respo-font-text text-right">
          {listItem?.qty ? `${listItem.qty}` : '0'}
        </label>
      </div>
      <div className="flex items-baseline overflow-hidden border-right pxy-2">
        <label className="inter-400-text natural-900-text two-lines line-height-20 font-14 respo-font-text">
          {getFormattedNumber(listItem?.unit_price || 0)}
        </label>
      </div>
      <div className="flex items-baseline overflow-hidden border-right pxy-2">
        <label className="inter-400-text natural-900-text two-lines line-height-20 font-14 respo-font-text">
          {getFormattedNumberStyle((listItem?.discount_rate || 0) / 100, 'percent')}
        </label>
      </div>
      <div className="flex items-baseline overflow-hidden border-right pxy-2">
        <label className="inter-400-text natural-900-text two-lines line-height-20 font-14 respo-font-text">
          {getFormattedNumberStyle((listItem?.unit_tax_rate || 0) / 100, 'percent')}
        </label>
      </div>
      <div className="flex items-baseline overflow-hidden border-right pxy-2">
        <label className="inter-400-text natural-900-text two-lines line-height-20 font-14 respo-font-text">
          {getFormattedNumber(listItem?.total_amount || 0)}
        </label>
      </div>
    </QuoteProductListItemWrapper>
  );
};

const QuoteProductListItemWrapper = styled.div`
  min-height: 56px;

  .respo-font-text {
    @media (max-width: 576px) {
      font-size: 12px !important;
    }
  }
  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }

  .status-text {
    font-size: 14px;
  }
`;

export default QuoteProductListItem;
