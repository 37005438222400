import React, { Fragment, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';

import { ModalWrapper } from './modal.styled';

import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import QuoteAccept from './quotes/quote-accept';
import QuoteReject from './quotes/quote-reject';

const PopupComponents = {
  'quotes-reject': QuoteReject,
  'quotes-accepted': QuoteAccept,
};

const Modal = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    const Component = PopupComponents[modal?.type];
    if (Component) {
      return <Component />;
    }
    return <Fragment />;
  };

  if (!modal?.type) return null;

  return (
    <CSSTransition appear classNames="modal-transition" in timeout={100}>
      <ModalWrapper
        id="modal-wrapper-id"
        onclick={onClick}
        style={{ justifyContent: modal.content?.top ? 'start' : 'center' }}>
        <CSSTransition key="modal-content-id" appear classNames="popup-in" in timeout={200}>
          <div
            key="modal-content-id"
            ref={ref}
            className="modal-content"
            id="modal-content-id"
            style={{
              borderRadius: modal.content?.noRadius ? '0' : '16px',
              marginTop: modal.content?.top ? '80px' : '0',
            }}>
            {setPopupComponent()}
          </div>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>
  );
};

export default Modal;
