import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as FileIcon } from '../../assets/icons/File.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/ToastClose.svg';
import IconContainer from '../../components/common/icon-container';
import { removeActionToast } from '../../store/features/toastActionSlice';

const ToastAction = () => {
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toastAction);

  const onRemoveToast = toast => {
    dispatch(removeActionToast(toast));
  };

  function formatSize(sizeInKB) {
    if (sizeInKB < 1024) {
      return sizeInKB.toFixed(2) + ' KB';
    } else {
      const sizeInMB = sizeInKB / 1024.0;
      return sizeInMB.toFixed(2) + ' MB';
    }
  }

  const toastsReducer = toasts.reduce((acc, curr) => {
    if (!acc.find(toast => toast.id === curr.id)) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const DownloadToast = ({ toast }) => {
    return (
      <div className="flex pt-2 download-toast pb-2 items-start justify-between radius-2 mb-2 toast pr-3">
        <div className="ml-3">
          <div
            className="h-12 w-12 flex items-center justify-center radius-1_5"
            style={{
              background: '#E5E5E5',
            }}>
            <FileIcon className="file-svg" />
          </div>
        </div>
        <div className="flex flex-1 items-center toast-left ">
          <div className="flex-1 flex-column ml-3">
            <div className="flex items-center">
              <label className="color-neutral-900 line-height-20 text-ellipsis max-w-17 font-14">{toast.text}</label>
              <span className="grey-text-04 font-12 ml-1 line-height-20 mr-auto nowrap">
                ({formatSize(toast.fileSize)})
              </span>
              <IconContainer
                iconContainerClassname="cursor"
                Icon={CloseIcon}
                onClick={() => onRemoveToast(toast)}
                iconColor="natural_500"
                backgroundColor="transparent"
              />
            </div>
            <div className="flex justify-between items-center col-gap-3">
              <ToastProgress progress={`${toast.progress}%`} />
              <span className={`grey-text-04 font-12 ml-1 line-height-20 ml-auto percentage-${toast.id}`}>
                {toast.progress.toFixed(0)}%
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ToastActionWrapper className="ignore-click">
      {toasts && (
        <TransitionGroup>
          {toastsReducer.map(
            (toast, i) =>
              toast?.type === 'DOWNLOAD' && (
                <CSSTransition key={i} classNames="fade" timeout={200}>
                  <DownloadToast toast={toast} />
                </CSSTransition>
              ),
          )}
        </TransitionGroup>
      )}
    </ToastActionWrapper>
  );
};

const ToastActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 24px;
  bottom: 24px;
  z-index: 50000;

  .max-w-17 {
    max-width: 275px;
  }

  .toast {
    background: #ffffff;
    box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.1);
    /* height: 64px; */
    min-width: 340px;
    user-select: none;

    .file-svg {
      width: 20px;
      height: 24px;
      color: ${({ theme }) => theme.natural_500};
    }
    .toast-left {
      .toast-color {
        width: 4px;
        height: 64px;
        background: ${({ theme }) => theme.primary_500};
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      .error-toast-color {
        background: ${({ theme }) => theme.error_500};
      }
    }

    .close-btn {
      margin-right: 16px;
      margin-left: 24px;
      cursor: pointer;
    }
  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }
`;

const ToastProgress = styled.div`
  position: relative;
  max-width: 296px;
  height: 4px;
  width: 100%;
  border-radius: 100px;
  background: #e5e5e5;
  overflow: hidden;
  &::before {
    position: absolute;
    content: '';
    background: #2496ff;
    top: 0;
    left: 0;
    width: ${({ progress }) => progress};
    height: 100%;
    transition: 100ms ease width;
  }
`;

export default ToastAction;
