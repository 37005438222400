import React from 'react';

import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import Main from '../pages/main/main';
import Toast from '../popup/toast/toast';
import ToastAction from '../popup/toast/toast-action';
import history from './history';

export const Router = () => {
  return (
    <HashRouter history={history}>
      <Routes>
        <Route element={<Main />} path="/quotes/:quote_id" />
        <Route element={<div>Not found</div>} path="/not_found" />
        <Route path="/*" element={<Navigate replace to={'/not_found'} />} />
      </Routes>
      <Toast />
      <ToastAction />
    </HashRouter>
  );
};
