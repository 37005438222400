import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/elements/loader';
import QuotesPrivew from '../../components/quotesPreview';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription } from '../../helpers/utils';
import { useQuery } from '../../hooks/useQuery';
import Modal from '../../popup';
import { getQuoteDetails } from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';
import { MainWrapper } from '../../styles/pages/main.styled';

const Main = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const query = useQuery();
  const token = query.get('token');

  const { quote_id } = useParams();

  const [isQuoteDataLoading, setIsQuoteDataLoading] = useState(false);
  const [quoteDetailsError, setQuoteDetailsError] = useState('');
  const [quoteDetails, setQuoteDetails] = useState({});
  const [modal, setModal] = useState();

  const fetchQuoteDetails = async () => {
    setIsQuoteDataLoading(true);
    setQuoteDetailsError('');
    dispatch(getQuoteDetails({ quote_id: quote_id, token }))
      .then(quoteData => setQuoteDetails(quoteData))
      .catch(error => {
        const errorDescription = getErrorDescription(error, t('ERROR_QUOTES_DETAILS'));
        setQuoteDetailsError(errorDescription);
        dispatch(addToast({ error: true, text: errorDescription, id: nanoid() }));
      })
      .finally(() => {
        setIsQuoteDataLoading(false);
      });
  };

  useEffect(() => {
    if (quote_id) {
      fetchQuoteDetails();
    } else {
      navigate(`/not_found`, { replace: true });
    }
  }, [quote_id]);

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(regs => regs.forEach(reg => reg.update()));
  }, [location]);

  return (
    <MainWrapper>
      <OrganisationContext.Provider value={{ modal, setModal, fetchQuoteDetails, token }}>
        <div
          className={classNames(
            'flex-column items-center main relative w-full',
            (quoteDetails?.status === 'DRAFT' || quoteDetailsError) && 'justify-center',
          )}>
          {isQuoteDataLoading ? (
            <Loader />
          ) : (
            <QuotesPrivew
              quoteDetails={quoteDetails}
              isQuoteDataLoading={isQuoteDataLoading}
              quoteDetailsError={quoteDetailsError}
            />
          )}
        </div>
        <Modal />
      </OrganisationContext.Provider>
    </MainWrapper>
  );
};

export default Main;
