import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CrossIcons.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';
import { capitalize, formatText, getFormattedNumber } from '../../../helpers/utils';
import { useWindowSize } from '../../../hooks/useWindowSize';
import Button from '../button/button';

const RejectedQuote = ({ label, value }) => (
  <div className="flex items-center ">
    <p className="inter-400-text error-text font-14 line-height-9 ">{value}</p>
  </div>
);

const Header = ({ onReject, onAccept, quoteDetails, dateExpiry, showActionButtons }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <QuotesHeaderWrapper className={classNames('flex items-center justify-center py-5')}>
      <div
        className={classNames(
          'w-60 mx-auto flex items-center justify-between respo-sec',
          width <= 576 && 'justify-center',
        )}>
        <div className={classNames(width <= 576 && 'total-sec')}>
          <p className="inter-600-text natural-900-text font-20 line-height-28px">
            {t('TOTAL')}: {quoteDetails?.total_amount ? getFormattedNumber(quoteDetails?.total_amount) : '-'}
          </p>
          <p className="inter-400-text natural-400-text font-14 line-height-20">
            {t('QUOTE_EXPIRES')}: {dateExpiry || '-'}
          </p>
        </div>
        {showActionButtons && (
          <div className=" flex items-center col-gap-6">
            <Button
              label={t('REJECT')}
              className={width <= 576 ? ' negative px-5 py-3' : 'negative_white px-4 py-2'}
              size={width <= 576 ? 'large' : 'medium'}
              width={width <= 576 ? '136px' : '113px'}
              height={width <= 576 ? '48px' : '40px'}
              lableSize="font-14"
              icon={<CloseIcon className={width <= 576 ? ' white-text mr-0' : ' error-text mr-0'} />}
              onClick={onReject}
            />
            <Button
              label={t('ACCEPT')}
              className={width <= 576 ? 'primary  px-5 py-3' : 'primary-blue px-4 py-2'}
              size={width <= 576 ? 'large' : 'medium'}
              width={width <= 576 ? '136px' : '113px'}
              height={width <= 576 ? '48px' : '40px'}
              lableSize="font-14"
              icon={<CheckIcon className={width <= 576 ? 'white-text mr-0' : 'primary-500-text mr-0'} />}
              onClick={onAccept}
            />
          </div>
        )}
        {quoteDetails?.status === 'ACCEPTED' && (
          <Button
            label={capitalize(formatText(quoteDetails?.status))}
            className="success-white px-4 py-2  "
            size="medium"
            width="113px"
            height="40px"
            lableSize="font-14 "
          />
        )}
        {quoteDetails?.status === 'REJECTED' && (
          <Button
            label={`${capitalize(formatText(quoteDetails?.status))}:`}
            className="negative_white px-4 py-2 "
            afterIcon={<RejectedQuote value={quoteDetails?.customer_note} />}
            size="medium"
            width="auto"
            height="40px"
            lableSize="font-14"
          />
        )}
      </div>
    </QuotesHeaderWrapper>
  );
};

const QuotesHeaderWrapper = styled.div`
  background-color: #fff;
  position: fixed;
  top: 0;
  height: 88px;
  width: 100%;
  box-shadow: 0px 4px 30px 3px #10182817;
  left: 0;
  @media (max-width: 576px) {
    bottom: 0 !important;
    top: initial !important;
    box-shadow: 0px -4px 30px 3px #10182817;
  }

  .total-sec {
    display: none !important;
  }
  .respo-sec {
    @media (max-width: 1280px) {
      width: 70% !important;
    }
    @media (max-width: 1024px) {
      width: 80% !important;
    }
    @media (max-width: 768px) {
      width: 100% !important;
      padding: 0 32px !important;
    }
  }
`;

export default Header;
