import { configureStore } from '@reduxjs/toolkit';
import quoteSlice from './features/quoteSlice';
import reloadSlice from './features/reloadSlice';
import ToastActionSlice from './features/toastActionSlice';
import toastSlice from './features/toastSlice';

export default configureStore({
  reducer: {
    reload: reloadSlice,
    toast: toastSlice,
    quote: quoteSlice,
    toastAction: ToastActionSlice,
  },
  devTools: process.env.REACT_APP_STAGE === 'development',
});
