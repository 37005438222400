import styled from 'styled-components';

export const MainWrapper = styled.div`
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  overflow: hidden;

  .main {
    display: flex;
    flex: 1;
    background-color: #f0f0f0;

    .top-border-bar {
      height: 16px;
      background: ${({ theme }) => theme.field_accent_color_text};
    }

    .content {
      z-index: 1;
    }

    .fade-appear {
      opacity: 0;
      transform: translate(0, 15px);
    }

    .fade-appear.fade-appear-active {
      opacity: 1;
      transform: unset;
      transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    }

    .fade-enter {
      opacity: 0;
      transform: translate(0, 15px);
    }

    .fade-enter.fade-enter-active {
      opacity: 1;
      transform: unset;
      transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    }

    .fade-exit {
      opacity: 1;
      transform: unset;
    }

    .fade-exit.fade-exit-active {
      opacity: 0;
      transform: translate(0, 15px);
      transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    }

    .fade-exit-done {
      opacity: 0;
      transform: unset;
    }
  }
`;
