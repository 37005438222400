import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const NoData = ({ title = '', description = '', className = '' }) => {
  return (
    <NoDataWrapper className={classNames('flex-column items-center justify-center w-full', className)}>
      <div className={classNames('text-center')}>
        <label className={classNames('font-14 text-center inter-500-text natural-500-text')}>{title}</label>
        <p className="font-14 text-center inter-400-text natural-500-text description">{description}</p>
      </div>
    </NoDataWrapper>
  );
};

const NoDataWrapper = styled('div')`
  .description {
    width: min(400px, 70%);
  }
`;

export default NoData;
