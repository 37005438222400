import { createSlice } from '@reduxjs/toolkit';
import api from '../services/index';

export const quoteSlice = createSlice({
  name: 'quote',
  initialState: {},
  reducers: {},
});

const getHeaderWithToken = token => ({ headers: { authorization: `Bearer ${token}` } });

export const getQuoteDetails =
  ({ quote_id, token, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/self-service/quotes/${quote_id}`, getHeaderWithToken(token), {
        params: params,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const selfServiceStatusQuote = payload => async dispatch => {
  try {
    const { quote_id, token, request } = payload;
    const { data } = await api.put(`/self-service/quotes/${quote_id}/status`, request, getHeaderWithToken(token));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getQuoteAttachmentDetails =
  ({ quote_id, attachment_id, token }) =>
  async dispatch => {
    try {
      const { data } = await api.get(
        `/self-service/quotes/${quote_id}/attachments/${attachment_id}`,
        getHeaderWithToken(token),
      );
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {} = quoteSlice.actions;
export default quoteSlice.reducer;
