import { createGlobalStyle } from 'styled-components';
import { FontStyles } from './fonts.styled';

export const CommonStyleWrapper = createGlobalStyle`
${FontStyles};

.card {
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 6px 20px rgba(103, 103, 103, 0.1);
  }

  .box-card {
    border-radius: 12px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:hover{
      box-shadow: 0px 20px 25px -5px #1018281A;
      cursor: pointer;
      transition: 0.5s all;
    }
  }

  * :focus-visible {
    outline: none;
  }
  a {
    text-decoration:none;
  }
  .input {
    height: 44px;
    border: 1px solid ${({ theme }) => theme.lightGrey};
    border-radius: 6px;
    color: ${({ theme }) => theme.darkBlue};
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    outline: none;
    padding: 12px 16px;
  }

  .input:focus {
    border: 1px solid ${({ theme }) => theme.focus_border};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
  }
  .input::placeholder {
    // opacity: 0.3;
    color: ${({ theme }) => theme.natural_400};
}

  .textarea {
    width: 100%;
    min-height: 80px;
    border: 1px solid ${({ theme }) => theme.lightGrey};
    border-radius: 6px;
    color: ${({ theme }) => theme.darkBlue};
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    outline: none;
    padding: 12px 16px;
    resize: none;
  }

  .textarea:focus {
    border: 1px solid ${({ theme }) => theme.focus_border};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
  }

  .textarea::placeholder {
    opacity: 0.3;
  }

  .success-message-alert{
    padding-right: 0px;
    gap: 20px;
    align-self: stretch;
    border-radius: 6px;
    background:  ${({ theme }) => theme.success_50};
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.10), 0px 4px 6px -1px rgba(16, 24, 40, 0.10);
    border-left:4px solid green;
    padding:0 20px
  }

  .success-text {
    color : green;
  }
  .black-text {
    color:#000;
  }
  .error-border {
    border: 1px solid #FCA5A5;
    box-shadow: 0px 0px 0px 4px #FEE2E2;
  }
  .error-border:focus {
    border: 1px solid #FCA5A5;
    box-shadow: 0px 0px 0px 4px #FEE2E2;
  }

  .error-message-alert {
    padding-right: 0px;
    gap: 20px;
    align-self: stretch;
    border-radius: 6px;
    background: #FEF2F2;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.10), 0px 4px 6px -1px rgba(16, 24, 40, 0.10);
    border-left:4px solid #EF4444;
    padding:0 20px
  }

  .box-active {
    border: 1px solid #2496FF;
    background: #EFF6FF;
  }

  .loader {
    display: flex;
    position: absolute;
    align-items: center;
    background: #05314933;
    border-radius: 16px;
    justify-content: center;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }

  .text-start{
    text-align: start;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .flex {
    display: flex;
  }
  .inline-flex-column {
    display: inline-flex;
    flex-direction: column
  }
  .block {
    display: block;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .items-center {
    align-items: center;
  }

  .items-baseline {
    align-items: baseline;
  }

  .items-end {
    align-items: flex-end;
  }

  .items-start {
    align-items: flex-start;
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
    border: none;
    margin: 0;
  }

  p {
    margin: 0px;
  }

  .line-height-1 {
    line-height: 1;
  }
  .line-height-9 {
    line-height: 9px;
  }
  .line-height-20 {
    line-height: 20px;
  }
  .line-height-24{
    line-height: 24px
  }
  .line-height-28 {
    line-height: 28px;
  }
  .line-height-36 {
    line-height: 36px;
  }

  .line-height-150{
    line-height: 150%
  }

  .letter-spacing-1 {
    letter-spacing: 1px;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  .col-gap-3px {
    column-gap: 3px;
  }
  .col-gap-1 {
    column-gap: 4px;
  }

  .col-gap-2 {
    column-gap: 8px;
  }

  .col-gap-3 {
    column-gap: 12px;
  }

  .col-gap-4 {
    column-gap: 16px;
  }

  .col-gap-5 {
    column-gap: 20px;
  }

  .col-gap-6 {
    column-gap: 24px;
  }
  .col-gap-8 {
    column-gap: 28px;
  }

  .col-gap-10 {
    column-gap: 40px;
  }

  .row-gap-2px {
    row-gap: 2px;
  }
  .row-gap-1 {
    row-gap: 4px;
  }

  .row-gap-2 {
    row-gap: 8px;
  }

  .row-gap-3 {
    row-gap: 12px;
  }

  .row-gap-4 {
    row-gap: 16px;
  }

  .row-gap-5 {
    row-gap: 20px;
  }

  .row-gap-6{
    row-gap: 24px;
  }

  .row-gap-8 {
    row-gap: 32px;
  }

  .row-gap-10 {
    row-gap: 40px;
  }

  .fw-w {
    flex-wrap: wrap;
  }
  .color-neutral-900 {
    color: #171717
  }
  .bg-white {
    background-color: #fff;
  }
  .bg-blue {
    background-color: #2496ff;
  }
  .bg-lightblue {
    background-color: #D3EAFE;
  }
  .bg-lightyellow{
    background: #FFFBEB
  }
  .bg-lightblue-hover{
    &:hover{
      background-color: #D3EAFE;
    }
  }

  .bg-lightgray-1{
    background-color: #F5F5F5;
  }

  .bg-lightgray-1-active{
    background-color: #EFF6FF;
  }

  .gray-avatar .color-light-black-1,
  .color-light-black-1{
    color: #404040;
  }
  .color-yellow{
    color: #F59E0B
  }
  .bg-yellow{
    color: #F59E0B
  }
  .bg-pink {
    background-color:#F3EAFE
  }

  .text-black {
    color: #000;
  }

  .bg-natural-50 {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .bg-natural-100 {
    background-color: ${({ theme }) => theme.natural_100};
  }

  .bg-natural-150 {
    background-color: ${({ theme }) => theme.natural_150};
  }

  .bg-natural-200 {
    background-color: ${({ theme }) => theme.natural_200};
  }

  .bg-natural-500 {
    background-color: ${({ theme }) => theme.natural_500};
  }

  .bg-purple-50 {
    background-color: ${({ theme }) => theme.purple_50};
  }

  .bg-purple-500 {
    background-color: ${({ theme }) => theme.purple_500};
  }

  .bg-success-50 {
    background-color: ${({ theme }) => theme.success_50};
  }

  .bg-primary-50 {
    background-color: ${({ theme }) => theme.primary_50};
  }

  .color-gray {
    color: #73868c;
  }

  .color-gray-secondary{
    color: #737373
  }

  .color-blue {
    color: #2496ff;
  }
  .color-purple {
    color:#8324FF
  }

  .color-success_500{
    color: #22C55E;
  }

  .ml-4 {
    margin-left: 16px;
  }

  .ml-5 {
    margin-left: 20px;
  }

  .ml-6 {
    margin-left: 24px;
  }

  .ml-8 {
    margin-left: 32px;
  }

  .ml-10 {
    margin-left: 40px;
  }

  .ml-12 {
    margin-left: 48px;
  }

  .ml--1_5{
    margin-left: -6px
  }

  .mr-0{
    margin-right: 0px !important;
  }

  .mr-auto {
    margin-right: auto;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mr-6 {
    margin-right: 24px;
  }

  .mr-5 {
    margin-right: 20px;
  }

  .mr-10 {
    margin-right: 40px;
  }

  .mr-12 {
    margin-right: 48px;
  }

  .mr--16 {
    margin-right: 64px;
  }

  .mr-8 {
    margin-right: 32px;
  }

  .mr-7 {
    margin-right: 28px;
  }

  .mr-4 {
    margin-right: 16px;
  }

  .mr-1_5 {
    margin-right: 6px;
  }

  .ml-2 {
    margin-left: 8px;
  }

  .ml-1 {
    margin-left: 4px;
  }

  .ml-1_5 {
    margin-left: 6px;
  }

  .ml-3 {
    margin-left: 12px;
  }

  .ml-4 {
    margin-left: 16px;
  }

  .ml-5 {
    margin-left: 20px;
  }

  .ml-6 {
    margin-left: 24px;
  }

  .ml-8 {
    margin-left: 32px;
  }

  .ml-10 {
    margin-left: 40px;
  }

  .ml-12 {
    margin-left: 48px;
  }

  .ml--1_5{
    margin-left: -6px
  }


  .mr-1 {
    margin-right: 4px;
  }

  .mr-2 {
    margin-right: 8px;
  }

  .mr-3 {
    margin-right: 12px;
  }

  .mr-16 {
    margin-right: 16px;
  }

  .mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }

  .mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }

  .mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }

  .mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }

  .mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mxy-6 {
    margin: 24px;
  }

  .mxy-2 {
    margin: 8px;
  }

  .mxy-3 {
    margin: 12px;
  }

  .mxy-05 {
    margin: 2px;
  }

  .m-0{
    margin: 0px;
  }

  .mt-1 {
    margin-top: 4px;
  }

  .mt-2 {
    margin-top: 8px;
  }

  .mt-3 {
    margin-top: 12px;
  }

  .mt-4 {
    margin-top: 16px;
  }

  .mt-5 {
    margin-top: 20px;
  }

  .mt-6 {
    margin-top: 24px;
  }

  .mt-7 {
    margin-top: 28px;
  }

  .mt-8 {
    margin-top: 32px;
  }

  .mt-10 {
    margin-top: 40px;
  }

  .mt-12 {
    margin-top: 48px;
  }

  .mt-16 {
    margin-top: 64px;
  }

  .mt-20 {
    margin-top: 80px;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 4px;
  }

  .mb-2 {
    margin-bottom: 8px;
  }

  .mb-3 {
    margin-bottom: 12px;
  }

  .mb-4 {
    margin-bottom: 16px;
  }
  .mb-5 {
    margin-bottom: 20px;
  }

  .mb-6 {
    margin-bottom: 24px;
  }

  .mb-6_5 {
    margin-bottom: 28px;
  }

  .mb-8 {
    margin-bottom: 32px;
  }

  .mb-10 {
    margin-bottom: 40px;
  }

  .mb-12 {
    margin-bottom: 48px;
  }

  .mb-20 {
    margin-bottom: 80px;
  }

  .my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }

  .justify-start {
    justify-content: start;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: end;
  }
  .spacer {
    flex: 1 1 auto;
  }

  .p-3{
    padding: 12px
  }

  .p-0 {
    padding: 0px !important;
  }

  .pr-1 {
    padding-right: 4px;
  }

  .pr-1_5 {
    padding-right: 6px;
  }

  .pr-2 {
    padding-right: 8px;
  }

  .pr-3 {
    padding-right: 12px;
  }

  .pr-4 {
    padding-right: 16px;
  }

  .p-4{
    padding: 16px;
  }

  .p-6{
    padding: 24px;
  }

  .pr-5 {
    padding-right: 20px;
  }

  .pr-6 {
    padding-right: 24px;
  }

  .pr-7 {
    padding-right: 30px;
  }

  .pr-12 {
    padding-right: 48px;
  }

  .pl-1 {
    padding-left: 4px;
  }
  .pl-1_5 {
    padding-left: 6px;
  }
  .pl-2 {
    padding-left: 8px;
  }

  .pl-3 {
    padding-left: 12px;
  }

  .pl-4 {
    padding-left: 16px;
  }

  .pl-5 {
    padding-left: 20px;
  }

  .pl-6 {
    padding-left: 24px;
  }

  .pl-8 {
    padding-left: 32px;
  }

  .pt-6 {
    padding-top: 24px;
  }

  .pt-8 {
    padding-top: 32px;
  }

  .pt-10 {
    padding-top: 40px;
  }

  .pt-1 {
    padding-top: 4px;
  }
  .pt-1_5 {
    padding-top: 6px;
  }
  .pt-2 {
    padding-top: 8px;
  }

  .pt-3 {
    padding-top: 12px;
  }

  .pt-4 {
    padding-top: 16px;
  }
  .pt-4_5 {
    padding-top: 18px;
  }

  .pt-5 {
    padding-top: 20px;
  }

  .pb-0_5 {
    padding-bottom: 2px;
  }
  .pb-1 {
    padding-bottom: 4px;
  }
  .pb-1_5 {
    padding-bottom: 6px;
  }
  .pb-2 {
    padding-bottom: 8px;
  }
  .pb-3 {
    padding-bottom: 12px;
  }
  .pb-4 {
    padding-bottom: 16px;
  }

  .pb-5 {
    padding-bottom: 20px;
  }
  .pb-5_5 {
    padding-bottom: 22px;
  }

  .pb-6 {
    padding-bottom: 24px;
  }

  .pb-7{
    padding-bottom: 28px;
  }

  .pb-8 {
    padding-bottom: 32px;
  }

  .pb-9{
    padding-bottom: 36px
  }

  .pb-12 {
    padding-bottom: 48px;
  }

  .pb-10 {
    padding-bottom: 40px;
  }

  .pb-3 {
    padding-bottom: 12px;
  }

  .px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .px-5_5 {
    padding-left: 22px;
    padding-right: 22px;
  }

  .px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .px-1_5 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .px-2_5{
    padding-left: 10px;
    padding-right: 10px;
  }

  .px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .px-7 {
    padding-left: 28px;
    padding-right: 28px;
  }

  .px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }

  .px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }

  .py-0{
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .py-0_5 {
    padding-top: 2px;
    padding-bottom: 2px;
  }


  .py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .py-1_5 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-2_5 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-4_5 {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pl-6 {
    padding-left: 24px;
  }

  .pl-10 {
    padding-left: 40px;
  }

  .py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .pxy-0_5 {
    padding:2px;
  }

  .pxy-1 {
    padding: 4px;
  }

  .pxy-1_5 {
    padding: 5px;
  }

  .pxy-1-5 {
    padding: 6px;
  }

  .pxy-1-2 {
    padding: 4px 8px;
  }

  .pxy-2 {
    padding: 8px;
  }

  .pxy-3 {
    padding: 12px;
  }

  .pxy-4 {
    padding: 16px;
  }

  .pxy-5 {
    padding: 20px;
  }

  .pxy-6 {
    padding: 24px;
  }

  .pxy-8 {
    padding: 32px;
  }

  .pxy-10 {
    padding: 40px;
  }

  .pxy-12 {
    padding: 48px;
  }

  .pxy-2-10 {
    padding: 2px 10px;
  }

  .pxy-2-4 {
    padding: 8px 16px;
  }

  .pxy-3-4 {
    padding: 12px 16px;
  }

  .pxy-3-8 {
    padding: 12px 24px;
  }

  .pxy-4-2 {
    padding: 16px 8px;
  }

  .font-24 {
    font-size: 24px;
  }

  .font-22 {
    font-size: 22px;
  }

  .font-28 {
    font-size: 28px;
  }

  .font-32 {
    font-size: 32px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-40 {
    font-size: 40px;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-8 {
    font-size: 8px !important;
  }

  .font-9 {
    font-size: 9px !important;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-10 {
    font-size: 10px;
  }

  .font-20 {
    font-size: 20px;
  }

  .border-dot {
    border: 2px dashed #d9e3ea;
  }

  .border-0 {
    border: none;
  }

  .border-1 {
    border: 1px solid #d9e3ea;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .right-0 {
    right:0
  }

  .fixed {
    position: fixed;
  }

  .m-auto {
    margin: auto;
  }

  .min-h-full {
    min-height: 100%;
  }

  .h-fit-content {
    height: fit-content;
  }

  .h-14px {
    height: 14px;
  }

  .h-full {
    height: 100%;
  }

  .h-full-vh {
    height: 100vh;
  }

  .h-content {
    height: max-content;
  }

  .h-auto {
    height: auto
  }

  .h-24px {
    height:24px;
  }

  .h-28px {
    height:28px;
  }

  .h-32px {
    height:32px;
  }

  .h-40px {
    height:40px;
  }
  .h-48px {
    height:48px;
  }

  .w-14px {
    width: 14px;
  }

  .w-24px {
    width: 24px;
  }

  .w-100px{
    width: 100px;
  }

  .w-30 {
    width: 30%;
  }

  .w-20 {
    width: 20%;
  }
  .w-32px {
    width:32px;
  }

  .w-48px {
    width: 48px;
  }
  .w-64 {
    width: 64px;
  }
  .w-62px {
    width: 62px;
  }
  .w-82px {
    width: 82px;
  }

  .w-50 {
    width: 50%;
  }

  .w-60 {
    width: 60%;
  }

  .w-70 {
    width: 70%;
  }

  .w-75 {
    width: 75%;
  }

  .w-full {
    width: 100%;
  }

  .w-full-vh{
    width: 100vh;
  }

  .min-w-full {
    min-width: 100%;
  }

  .w-auto {
    width:auto;
  }

  .w-6 {
    width:20px;
  }
  .h-6 {
    height: 20px
  }
  .cursor * {
    cursor: pointer;
  }

  .cursor {
    cursor: pointer;
  }

  .move-cursor {
    cursor: move;
  }

  .menu {
    border-radius: 8px;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.1);
    user-select: none;
  }

  .no-select {
    user-select: none;
  }

  .text-wrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 40px;
    overflow: hidden;
  }

  .nowrap {
    white-space: nowrap;
  }

  .white-space-pre {
    white-space: pre;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-x-scroll {
    overflow-x: scroll;
  }

  .overflow-y-scroll {
    overflow-y: scroll;
  }

  .overflow-scroll {
    overflow: scroll;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .white-text {
    color: #ffffff;
  }

  .blue-primary-icon {
    color: #2496FF;
  }

  .white-background {
     background: #FFF;
  }

  .grey-text {
    color: ${({ theme }) => theme.greyText};
  }

  .light-grey-text {
    color: #d2e5ed;
  }

  .light-purple-text {
    color: #aabab7;
  }

  .o05 {
    opacity: 0.5;
  }

  .o1 {
    opacity: 1;
  }

  .o2 {
    opacity: 0.2;
  }

  .o03 {
    opacity: 0.3;
  }
  .radius-100 {
    border-radius: 100%;
  }

  .radius-1 {
    border-radius: 4px;
  }
  .radius-1_3 {
    border-radius: 3px;
  }

  .radius-1_5 {
    border-radius: 6px;
  }

  .radius-2 {
    border-radius: 8px;
  }

  .radius-3 {
    border-radius: 12px;
  }

  .radius-4 {
    border-radius: 16px;
  }

  .radius-6 {
    border-radius: 24px;
  }

  .radius-full {
    border-radius: 100px;
  }

  .radius-r-100 {
    border-radius: 0 100px 100px 0;
  }
  .radius-full {
    border-radius: 100px;
  }
  .radius-50-percent {
    border-radius: 50%;
  }
  .radius-0 {
    border-radius: 0px !important;
  }
  .self-start {
    align-self: flex-start;
  }

  .self-center {
    align-self: center;
  }

  .self-streach{
    align-self: stretch;
  }

  .fw-500 {
    font-weight: 500;
  }

  .border-top {
    border-top: 1px solid ${({ theme }) => theme.natural_200};
  }

  .border-left {
    border-left: 1px solid ${({ theme }) => theme.natural_200};
  }

  .border-right {
    border-right: 1px solid ${({ theme }) => theme.natural_200};
  }

  .border-right-white {
    border-right: 1px solid ${({ theme }) => theme.white};
  }

  .border-bottom {
    border-bottom: 1px solid ${({ theme }) => theme.natural_200};
  }

  .border {
    border: 1px solid ${({ theme }) => theme.natural_200};
  }

  .border-top-light {
    border-top: 1px solid ${({ theme }) => theme.natural_100};
  }

  .border-left-light {
    border-left: 1px solid ${({ theme }) => theme.natural_100};
  }

  .border-right-light {
    border-right: 1px solid ${({ theme }) => theme.natural_100};
  }

  .border-bottom-light {
    border-bottom: 1px solid ${({ theme }) => theme.natural_100};
  }

  .border-light {
    border: 1px solid ${({ theme }) => theme.natural_100};
  }

  .border-blue {
    border: 1px solid ${({ theme }) => theme.selectedText};
  }

  .border-dotted {
    border: 1px dashed ${({ theme }) => theme.natural_200};
  }

  .grey-text-o03 {
    color: rgba(115, 134, 140, 0.3);
  }

  .grey-text-04{
    color: #A3A3A3
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-row-rev {
    flex-direction: row-reverse;
  }

  .gap-0_5 {
    gap: 2px;
  }

  .gap-0_7{
    gap: 3px;
  }

  .gap-1 {
    gap: 4px;
  }

  .gap-5px {
    gap: 5px;
  }

  .gap-6px {
    gap: 6px;
  }

  .gap-2 {
    gap: 8px;
  }

  .gap-2_5 {
    gap: 10px;
  }

  .gap-3 {
    gap: 12px;
  }

  .gap-4 {
    gap: 16px;
  }
  .gap-5 {
    gap: 20px;
  }
  .gap-6 {
    gap: 24px;
  }
  .gap-8 {
    gap: 32px;
  }
  .gap-10 {
    gap: 40px;
  }
  .gap-12 {
    gap: 48px;
  }

  .one-line {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .fit-content {
    height: fit-content;
  }

  .popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(11, 22, 35, 0.49);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    .popup-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      background: #ffffff;
      border: 1px solid rgba(240, 246, 249, 0.5);
      box-sizing: border-box;
      box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
      border-radius: 16px;
      outline: 0;
      margin-top: 64px;
      padding: 48px;
      position: relative;
    }
  }

  .grab-cursor * {
    cursor: grabbing;
  }

  .grab-cursor {
    cursor: grabbing;
  }

  .move-cursor * {
    cursor: move;
  }

  .move-cursor {
    cursor: move;
  }

  .default-cursor * {
    cursor: default;
  }

  .default-cursor {
    cursor: default;
  }

  .select,
  .input-select {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    background: transparent;
    // background-image: url(../images/dropdown.svg);
    background-repeat: no-repeat;
    background-position-x: calc(100% - 19px);
    background-position-y: 19px;
    padding-right: 38px;

    option {
      color: black;
      padding: 8px;
    }

    option:hover {
      background: #f6f6f6 !important;
    }
  }

  .input-select {
    background-image: none;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 16px;

    .date-selector {
      top: 65px;
      left: 0;

      .selected-day {
        background: #24cbb1;
      }
    }
  }

  .select:required:invalid {
    color: #aabab7;
  }

  .new-version-popup {
    top: 0;
    left: 0;
    z-index: 30;
    width: 100%;
    height: 100%;
    background: rgba(4, 32, 48, 0.8);
  }

  .refresh-view {
    height: 435px;
    width: 450px;

    .refresh-btn {
      height: 48px;
      width: 100%;
      background: ${({ theme }) => theme.primaryBlue};
      border-radius: 100px;
    }
  }

  .br-2 {
    border-radius: 8px;
  }

  * :focus-visible {
    outline: none;
  }

  * ::-webkit-scrollbar {
    width: 0;
    height: 0px;
  }

  * {
    scrollbar-width: none;
  }

  .two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .three-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .password-mask {
    text-security: disc; /* IE/Safari */
    -moz-text-security: disc; /* FireFox */
    -webkit-text-security: disc; /* Chrome/Safari  */
  }

  .w-fit-content {
    width: fit-content;
  }

  .disabled{
    pointer-events: none;
    cursor: not-allowed;
  }

  .rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.2s ease-in-out;
  }

  .rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }

  .rotate-270 {
    transform: rotate(270deg);
    transition: transform 0.2s ease-in-out;
  }

  .icon-wrapper {
    background: ${({ theme }) => theme.primaryGrey};
    border-radius: 6px;
    padding: 5px;
  }

  .description-input {
    min-height: 100px;
    resize: none;
    outline: none;
    max-height: 200px;
  }

  .error-border {
    border-color: ${({ theme }) => theme.error_500} !important;
  }

  .border-top-right-radius-0{
    border-top-right-radius: 0
  }

  .border-top-left-radius-0{
    border-top-left-radius: 0
  }

  .border-bottom-left-radius-0{
    border-bottom-left-radius: 0
  }

  .border-bottom-right-radius-0{
    border-bottom-right-radius: 0
  }

  .text-capitalize{
    text-transform: capitalize;
  }

  .text-uppercase{
    text-transform: uppercase;
  }

  .gray-avatar{
    background: #F0F0F0
  }

  .text-button{
    background:none;
    border:none;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .max-w-full{
    max-width: 100%;
  }
`;
