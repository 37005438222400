import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertInfoIcon } from '../../assets/icons/AlertInfoIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/elements/button/button';
import Signature from '../../components/elements/signature';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription, initModal } from '../../helpers/utils';
import { selfServiceStatusQuote } from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';

const QuoteAccept = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { quote_id } = useParams();

  const { modal, setModal, fetchQuoteDetails, token } = useContext(OrganisationContext);
  const { title } = modal || {};

  const [loading, setLoading] = useState(false);
  const [signaturValue, setSignaturevalue] = useState();
  const [error, setError] = useState({});

  const checkSignatureErrors = () => {
    if (!signaturValue?.valueSign) {
      setError({
        signError: !signaturValue?.valueSign,
      });
      dispatch(addToast({ error: true, text: 'please add signature' }));
      return true;
    }
    return false;
  };

  const handleConfirm = () => {
    const request = {
      status: 'ACCEPTED',
      customer_note: '',
      signature: signaturValue?.valueSign?.replace('data:image/png;base64,', ''),
    };
    if (checkSignatureErrors()) {
      return;
    }
    setLoading(true);
    dispatch(selfServiceStatusQuote({ quote_id: quote_id, token: token, request: request }))
      .then(() => {
        setModal(initModal);
        dispatch(addToast({ error: false, title: t('ACCEPTED'), text: t('ACCEPTED_QUOTES'), id: nanoid() }));
        fetchQuoteDetails();
      })
      .catch(error => {
        dispatch(
          addToast({
            error: true,
            text: getErrorDescription(error, t('FAILED_ACCEPTED_QUOTES')),
            id: nanoid(),
          }),
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <QuoteAcceptWrapper className="flex-column items-center p-6 gap-6">
        <div className="flex-column gap-5 content-wrapper items-center relative w-full">
          <AlertInfoIcon />
          <CrossIcon className="absolute right-0 cursor natural-500-text" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <p className="font-18 text-center inter-500-text natural-900-text">{title}</p>
          </div>
        </div>

        <div className="w-full flex-column ">
          <Signature
            key={''}
            name={t('SIGNATURE')}
            showRequiredError={error.signError}
            signatureValue={signaturValue}
            onChange={valueSign => {
              setSignaturevalue({ ...signaturValue, valueSign });
            }}
            heightcanva="240px"
          />
        </div>

        <div className="flex-column justify-center w-full gap-3">
          <Button
            className={classNames('primary w-full', loading && 'disabled')}
            loading={loading}
            label={t('ACCEPT')}
            onClick={() => {
              handleConfirm();
            }}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey w-full')}
            label={t('CANCEL')}
            size="large"
            disabled={loading}
            borderRadius="100px"
            bgColor={'#ffff'}
            onClick={() => setModal(initModal)}
            borderColor={'#2496FF'}
          />
        </div>
      </QuoteAcceptWrapper>
    </CSSTransition>
  );
};

const QuoteAcceptWrapper = styled.div`
  width: 420px;
  @media (max-width: 479px) {
    width: 345px !important;
  }
  @media (max-width: 320px) {
    width: 290px !important;
  }
  .box-signature {
    @media (max-width: 425px) {
      height: 195px !important;
    }
    @media (max-width: 320px) {
      height: 95px !important;
    }
  }
`;

export default QuoteAccept;
