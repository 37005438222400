import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/elements/button/button';
import InputElement from '../../components/elements/input';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription, initModal } from '../../helpers/utils';
import { selfServiceStatusQuote } from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';

const QuoteReject = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { quote_id } = useParams();

  const { modal, setModal, fetchQuoteDetails, token } = useContext(OrganisationContext);
  const { title } = modal || {};

  const [note, setNote] = useState('');
  const [signature, setSignature] = useState('');
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    const request = {
      status: 'REJECTED',
      customer_note: note,
      signature: '',
    };

    setLoading(true);
    dispatch(selfServiceStatusQuote({ quote_id: quote_id, token: token, request: request }))
      .then(() => {
        setModal(initModal);
        dispatch(addToast({ error: false, title: t('REJECTED'), text: t('REJECTED_QUOTES'), id: nanoid() }));
        fetchQuoteDetails();
      })
      .catch(error => {
        dispatch(
          addToast({
            error: true,
            text: getErrorDescription(error, t('FAILED_CANCELLED_QUOTES')),
            id: nanoid(),
          }),
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <QuoteRejectWrapper className="flex-column items-center p-6 gap-6">
        <div className="flex-column gap-5 content-wrapper items-center relative w-full">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor natural-500-text" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <p className="font-18 text-center inter-500-text natural-900-text">{title}</p>
          </div>
        </div>

        <div className="w-full flex-column">
          <InputElement
            name={t('NOTE')}
            onChange={e => {
              setNote(e);
            }}
            defaultValue={note}
            placeholder="Enter some description here"
            type="textarea"
          />
        </div>

        <div className="flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative delete-btn w-full', loading && 'disabled')}
            loading={loading}
            label={t('REJECT')}
            onClick={() => {
              handleConfirm();
            }}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey w-full', loading && 'disabled')}
            label={t('CANCEL')}
            size="large"
            disabled={loading}
            borderRadius="100px"
            bgColor={'#ffff'}
            onClick={() => setModal(initModal)}
            borderColor={'#2496FF'}
          />
        </div>
      </QuoteRejectWrapper>
    </CSSTransition>
  );
};

const QuoteRejectWrapper = styled.div`
  width: 420px;
  @media (max-width: 479px) {
    width: 345px !important;
  }
  @media (max-width: 320px) {
    width: 290px !important;
  }
`;

export default QuoteReject;
