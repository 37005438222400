import { createSlice } from '@reduxjs/toolkit';

export const toastActionSlice = createSlice({
  name: 'toastAction',
  initialState: {
    toasts: [],
    removedToast: [],
  },
  reducers: {
    newActionToast: (state, { payload }) => {
      state.toasts = [...state.toasts, { ...payload }];
    },
    removeActionToast: (state, { payload }) => {
      state.toasts = state.toasts.filter(t => t.id !== payload.id);
    },
    updateProgress: (state, { payload }) => {
      state.toasts = state.toasts.map(t =>
        payload.id === t.id
          ? {
              ...t,
              progress: payload.progress,
            }
          : t,
      );
    },
  },
});

export const addActionToast = data => dispatch => {
  dispatch(newActionToast(data));
};

export const { newActionToast, removeActionToast, updateProgress } = toastActionSlice.actions;
export default toastActionSlice.reducer;
